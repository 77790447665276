@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.seat-selection {
  &__card-dropdown {
    padding: 5px;
    margin-bottom: 8px;

    @include on-desktop {
      margin-bottom: 6px;
      max-width: 340px;
    }
  }
}
