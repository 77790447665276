@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.seat-selection {
  &__modal {
    @include on-desktop {
      padding: 30px 60px;
      overflow: auto;

      &.ui-modal {
        max-width: 1250px;
      }
    }

    .ui-modal__footer {
      margin-top: auto;

      @include on-desktop {
        box-shadow: none;
      }
    }

    .ui-modal__header {
      box-shadow: none;

      @include on-desktop {
        padding: 16px 0;
      }

      .font-icon-info {
        color: $color-seat-info-icon;
      }
    }

    .ui-modal__content {
      @include on-desktop {
        overflow: initial;
      }
    }

    .ui-tabs__header-label {
      flex: 1;
      text-align: center;

      @include on-desktop {
        flex: none;
      }
    }
  }

  &__tabs {
    @include on-mobile {
      background: $white;
      position: sticky;
      top: 0;
      left: 0;
      z-index: 1000;
      padding: 0 20px;
      margin-bottom: 12px;
    }

    &-fares {
      width: 100%;
      overflow: auto;
      margin-bottom: 10px;

      > div {
        padding: 5px;
      }

      @include on-desktop {
        width: auto;
        margin-bottom: 0;
      }

      & .ui-tabs__header-wrapper {
        flex-basis: unset;
      }

      & .journey-card__fare-dropdown {
        margin-bottom: 0;
      }

      & .font-icon-info {
        color: $color-seat-info-icon;
      }

      &-modal {
        & .ui-modal__content {
          padding: 0 20px 20px;
        }

        & .carriers__fares-feature-wrapper {
          display: grid;
        }

        & .ui-modal__header {
          box-shadow: none;
        }
      }
    }

    &-level {
      padding-bottom: 8px;

      @include on-desktop {
        min-width: 270px;
        padding-bottom: 0;
      }

      & .ui-tabs__header-label {
        flex: 1;
      }
    }
  }
}
