$color-seat-available: $color-primary-60;
$color-seat-selected: $color-secondary-80;
$color-seat-disabled: $color-grey-30;
$color-seat-paid: $color-primary-90;
$color-seat-discounted: $black;
$color-seat-thing-selected: $color-secondary-60;
$color-seat-label-disabled: $color-grey-80;
$color-seat-icon-disabled: $color-grey-50;
$color-seat-selection-bg: #fbfbfb;
$color-seat-selection-border: $color-grey-20;
$color-seat-lights-bg: #e7e6ee;
$color-seat-footer-fg: $color-grey-70;
$color-seat-footer-error: $color-error;
$color-train-seat-bg: $color-secondary-10;
$color-train-icon: $color-grey-50;
$color-seat-info-icon: #231a87;
$color-seat-accordion-bg: $color-grey-10;
