@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
.carriers {
  display: flex;
  justify-content: center;
  width: 100%;

  &__info {
    margin-bottom: 16px;
    flex: 1;

    @include on-desktop {
      margin: 0 32px;
    }

    @include on-tablet {
      margin: 0 25px;
    }

    &-logo {
      max-height: 26px;
      max-width: 100%;
    }

    &-name {
      @include font(14px, 16px, 500);

      color: $color-journey-card-carrier-name;
      margin-top: 4px;
      margin-left: 8px;
      text-align: center;
      white-space: pre;

      @include on-desktop {
        @include font(12px, 16px, 400);

        white-space: initial;
      }
    }
  }

  &__fares {
    display: flex;
    flex-direction: column;
    width: 100%;

    &-feature {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      &-wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
        gap: 12px 8px;
        padding: 0;
        margin-bottom: 16px;
        overflow: unset;

        &--media {
          grid-template-columns: repeat(4, auto);
          gap: 13px 25px;
        }

        @include on-mobile {
          @include gap(4px);

          display: flex;
          overflow: auto;
          padding: 0 12px 8px;
          position: relative;
          left: -20px;
          width: calc(100% + 40px);
          margin-bottom: 12px;
        }

        @include on-desktop {
          max-width: 256px;
        }

        .font-icon {
          color: $color-detailed-fare-icon;
          border: 1px solid $color-detailed-fare-icon-border;
          border-radius: 50%;
          padding: 15px;
        }
      }
    }

    &-description {
      @include font(10px, 16px, 400);

      margin-top: 8px;
      overflow-wrap: anywhere;
      width: 72px;
      font-family: $font-secondary;
    }

    &-class {
      &-title {
        @include font(14px, 20px, 500);

        color: $black;
      }

      &-info {
        display: flex;
        align-items: center;
        padding: 6px 16px 6px 12px;
        background: $color-fare-class-bg;
        border: 1px solid $color-border-fare-class;
        border-radius: 4px;
        width: fit-content;
        margin-bottom: 20px;
      }

      &-icon {
        display: flex;
        color: $color-fare-class-icon-fg;
        padding-right: 7px;

        :first-child {
          padding: 0;
          border: none;
        }
      }
    }

    &-blur {
      height: 96px;
      padding-left: 96px;
      position: sticky;
      right: -20px;
      background: linear-gradient(270deg, #fff 0%, rgb(255 255 255 / 0%) 100%);

      &--hide {
        display: none;
      }
    }

    &-emission {
      @include on-desktop {
        transform: translateX(-20px);
      }

      & .journey-list__emission {
        justify-content: start;

        > span {
          @include font(12px, 16px, 400);

          margin: auto 0;
        }
      }

      & .journey-list__emission-info {
        .font-icon {
          color: unset;
          border: unset;
        }
      }
    }

    & .fare-dropdown {
      margin-bottom: 20px;
    }
  }

  &__media {
    display: flex;
    padding: 5px;
    border: 1px solid $color-grey-20;
    border-radius: 8px;
    flex-direction: row;
    cursor: pointer;

    & .carriers__info {
      display: flex;
      flex-direction: row;
      flex: 1;
      max-width: 100%;
      margin: 0;
    }

    & .collection-content {
      width: 56px;
      height: 56px;
    }
  }
}
