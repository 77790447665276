@import "/app/src/styles/vars.scss";@import "/app/src/styles/media.scss";@import "/app/src/styles/mixins.scss";
$desktop-width: 256px;

.fare-dropdown {
  @include on-desktop {
    max-width: $desktop-width;
  }

  & .placeholder__value span {
    font-family: $font-secondary;
  }

  & .ui-dropdown__label .font-icon-chevron-down {
    border: none;
    padding: 0;
  }

  &__name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &__popper {
    @include on-desktop {
      max-width: $desktop-width;
    }

    & .ui-option__label span {
      @include font(16px, 24px, 500);

      font-family: $font-secondary;
    }
  }
}
